import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  asDefaultErrorAxios,
  asDefaultRegister,
  asSend,
  asSending,
  asSubmit
} from '../../../constants/constants/constAndRegex';
import { errorPropost } from '../../../constants/constants/constError';
import { requiredPropost } from '../../../constants/constants/requiredConst';
import { handleErrors, removeZero, settingState, validateForm } from '../../../constants/functions/functions';
import { URL_PROPOSAL } from '../../../constants/urls/urls';
import { GlobalContext } from '../../../global/GlobalContext';
import useForm from '../../../hooks/useForm';
import ClearDialog from '../../common/Dialog/ClearDialog/ClearDialog';
import Formulary from '../../common/Formulary/Formulary/Formulary';
import InputsPropost from '../../common/Formulary/InputsPropost/InputsPropost';
import { Button, ButtonRed } from '../../common/Reuse/Button/Buttons/Buttons';
import TitleModal from '../../common/Reuse/TitleModal/TitleModal';
import * as S from '../../common/StyledCommonModals/StyledCommonModals';
import * as L from './styled';

const EditPropost = (props) => {
  const {
    states: {
      globalState: { formData, id }
    },
    functions: { headerGlobalString, successToast, sayAlteration, errorToast }
  } = useContext(GlobalContext);
  const [clear, setClear] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { edit, setEdit } = props;

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage
  } = useForm();

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    edit,
    setEdit,
    clear,
    setClear,
    setFieldErrors
  };

  const handlePut = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const resultValidateForm = validateForm({
      specificValidations: requiredPropost,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage
    });

    if (!resultValidateForm) {
      setIsSubmitting(false);
      return errorToast(asDefaultErrorAxios);
    }

    if (!dataForm?.payment_method === 'a_vista') {
      dataForm.down_payment = '';
      dataForm.installments = '';
    }

    try {
      await axios.put(`${URL_PROPOSAL}${id}/`, dataForm, {
        headers: headerGlobalString()
      });
      sayAlteration();
      successToast(asDefaultRegister);
      settingState(setEdit, !edit);
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorPropost,
        errorToast
      });
    } finally {
      setIsSubmitting(false);
      console.log('objeto do envio: ', dataForm);
    }
  };

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_PROPOSAL}${id}/`, {
        headers: headerGlobalString()
      });
      const data = response.data
      const termConditionId = data.proposal_term_condition.id;
      const termConditionTypeIds = data.proposal_term_condition_type?.map(
        (type) => type.id
        );
        console.log('termConditionTypeIds', termConditionTypeIds)
      setDataForm((prevState) => ({
        ...prevState,
        ...data,
        project: data.project.id,
        term_condition: termConditionId,
        term_condition_type: termConditionTypeIds,
        value: removeZero(data.value),
        discount: removeZero(data.discount),
        down_payment: removeZero(data.down_payment),
      }));
    } catch (error) {
      console.log('Erro em getInfo');
    }
  };

  useEffect(() => {
    getInfo();
  }, []);
console.log(dataForm)
  return (
    <S.Main>
      <Formulary onClick={() => settingState(setEdit, !edit)}>
        <S.DivGap>
          <TitleModal text={'cadastrar'} />
          <InputsPropost {...propsList} />
        </S.DivGap>
        <L.DivGap>
          <ButtonRed
            onClick={formData.inputDisable !== true ? () => settingState(setClear, !clear) : undefined}
            named={'limpar'}
          />
          <Button
            type={formData.inputDisable !== true ? asSubmit : undefined}
            named={isSubmitting ? asSending : asSend}
            onClick={(event) => {
              if (!isSubmitting) {
                handlePut(event);
              }
            }}
            disabled={isSubmitting}
          />
        </L.DivGap>
      </Formulary>
      <ClearDialog {...propsList} />
    </S.Main>
  );
};
export default EditPropost;
