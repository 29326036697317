import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import FindInPageIcon from "@mui/icons-material/FindInPage"
import PhotoIcon from "@mui/icons-material/Photo"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import EmailIcon from "@mui/icons-material/Email"
import Modal from "@mui/material/Modal"
import axios from "axios"
import React, { useContext, useState } from "react"
import { toast } from "react-toastify"
import { asCompany, asCustomerCompany, asCustomerPhysical, asPeople } from "../../../constants/constants/constAndRegex"
import {
  URL_COMPANY,
  URL_PDF_COMPANY,
  URL_PDF_LETTEROFATTORNEY,
  URL_PDF_PHYSICAL,
  URL_PDF_PROPOSAL,
  URL_PDF_PROPOSAL_MAIL,
  URL_PHYSICAL,
  URL_PROPOSAL,
} from "../../../constants/urls/urls"
import { GlobalContext } from "../../../global/GlobalContext"
import * as S from "./StyledTable"

const Table = ({ column, state, onClickEdit, onClickDetail, onClickDelete, onClickEmail }) => {
  const {
    functions: { toggleData, headerGlobal },
    states: {
      globalState: { componentName },
    },
    functions: { successToast, errorToast },
    setters: { setGlobalState },
  } = useContext(GlobalContext)
  const [isRequestInProgress, setIsRequestInProgress] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [imageUrl, setImageUrl] = useState("")

  const openImageModal = (url) => {
    setImageUrl(url)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const toastId = React.useRef(null)

  const updateProgressToast = (message, percentCompleted) => {
    if (toastId.current === null) {
      toastId.current = toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      toast.update(toastId.current, {
        render: message,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })

      // Fechar o toast quando o download chegar a 100%
      if (percentCompleted === 100) {
        setTimeout(() => {
          toast.dismiss(toastId?.current)
          toastId.current = null
        }, 1000) // Aguarda 1 segundo antes de fechar
      }
    }
  }

  // PDF PROPOST
  const pdfPropost = (item) => {
    if (isRequestInProgress) {
      return
    }
    successToast("Aguarde alguns instantes...")
    setIsRequestInProgress(true)
    axios({
      method: "GET",
      url: `${URL_PDF_PROPOSAL}${item?.id}/`,
      responseType: "blob",
      headers: headerGlobal(),
      onDownloadProgress: (progressEvent) => {
        const total = progressEvent.total
        const current = progressEvent.loaded

        // Calcular a porcentagem de progresso
        const percentCompleted = Math.round((current / total) * 100)
        updateProgressToast(`O PDF está sendo gerado: ${percentCompleted}%`)
      },
    })
      .then((response) => {
        // Extrair o nome do arquivo do cabeçalho `Content-Disposition`
        const contentDisposition = response.headers["content-disposition"]
        let filename = "file.pdf" // valor padrão
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/i)
          if (match && match.length > 1) {
            filename = match[1]
          }
        }
        const url = window.URL.createObjectURL(new Blob([response?.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", filename) // Nome do arquivo extraído
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => {
        console.log("Error pdfClick", error)
        errorToast("Erro ao gerar PDF")
      })
      .finally(() => {
        setIsRequestInProgress(false)
      })
  }

  // guarda id no global state
  const IdGuard = (item) => {
    toggleData("id", item?.id) // adiciona id ao estado global
    onClickEmail(item?.id) // passa o id no click
  }

  // PDF URL_PDF_LETTEROFATTORNEY
  const pdfLetter = (item) => {
    if (isRequestInProgress) {
      return
    }
    successToast("Aguarde alguns instantes...")
    setIsRequestInProgress(true)
    axios({
      method: "GET",
      url: `${URL_PDF_LETTEROFATTORNEY}${item?.id}/`,
      responseType: "blob",
      headers: headerGlobal(),
      onDownloadProgress: (progressEvent) => {
        const total = progressEvent.total
        const current = progressEvent.loaded

        // Calcular a porcentagem de progresso
        const percentCompleted = Math.round((current / total) * 100)
        updateProgressToast(`O PDF está sendo gerado: ${percentCompleted}%`)
      },
    })
      .then((response) => {
        // Extrair o nome do arquivo do cabeçalho `Content-Disposition`
        const contentDisposition = response.headers["content-disposition"]
        let filename = "file.pdf" // valor padrão
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/i)
          if (match && match.length > 1) {
            filename = match[1]
          }
        }
        const url = window.URL.createObjectURL(new Blob([response?.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", filename) // Nome do arquivo extraído
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => {
        console.log("Error pdfLetter", error)
        errorToast("Erro ao gerar PDF")
      })
      .finally(() => {
        setIsRequestInProgress(false)
      })
  }

  const pdfMail = (item) => {
    if (isRequestInProgress) {
      return
    }
    successToast("Aguarde alguns instantes...")
    setIsRequestInProgress(true)
    axios({
      method: "POST",
      url: `${URL_PDF_PROPOSAL_MAIL}${item?.id}/`,
      headers: headerGlobal(),
    })
      .then((response) => {
        successToast("Email enviado com sucesso")
      })
      .catch((error) => {
        console.log("Error pdfLetter", error)
        errorToast("Erro ao gerar PDF")
      })
      .finally(() => {
        setIsRequestInProgress(false)
      })
  }

  //  PDFClient
  const pdfClickCLient = (item) => {
    if (isRequestInProgress) {
      return
    }
    successToast("Aguarde alguns instantes...")
    setIsRequestInProgress(true)
    const verifyType = "cnpj" in item ? asCompany : asPeople
    const axiosUrl = verifyType === asPeople ? URL_PDF_PHYSICAL : URL_PDF_COMPANY

    axios({
      method: "GET",
      url: `${axiosUrl}${item?.id}/`,
      responseType: "blob",
      headers: headerGlobal(),
      onDownloadProgress: (progressEvent) => {
        const total = progressEvent.total
        const current = progressEvent.loaded

        // Calcular a porcentagem de progresso
        const percentCompleted = Math.round((current / total) * 100)
        updateProgressToast(`O PDF está sendo gerado: ${percentCompleted}%`)
      },
    })
      .then((response) => {
        // Extrair o nome do arquivo do cabeçalho `Content-Disposition`
        const contentDisposition = response.headers["content-disposition"]

        let filename = "file.pdf"

        toast.dismiss(toastId.current)
        toastId.current = null

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/i)

          if (match && match.length > 1) {
            filename = match[1]
          }
        }

        const url = window.URL.createObjectURL(new Blob([response?.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", filename) // Nome do arquivo extraído
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => {
        console.log("Error pdfClickCLient", error)
        errorToast("Erro ao gerar PDF")
      })
      .finally(() => {
        setIsRequestInProgress(false)
        // Fechar o toast se ainda estiver aberto e o download não tiver chegado a 100%
      })
  }

  // detalhes
  const detail = (item) => {
    toggleData("id", item?.id) // adiciona id ao estado global
    onClickDetail(item?.id) // passa o id no click

    const verifyType = "cnpj" in item ? asCompany : asPeople // muda o valor no estado global formData.verifyType de acordo com pessoa física ou jurídica
    const axiosUrl = verifyType === asPeople ? URL_PHYSICAL : URL_COMPANY // muda o valor no estado global formData.verifyType de acordo com pessoa física ou jurídica

    // verifySelect usado em letterOfAttorney
    const verifySelect = // muda o valor no estado global formData.verifySelect de acordo com pessoa física ou jurídica
      item.customer_person_physical !== null
        ? asCustomerPhysical
        : item.customer_person_company !== null
        ? asCustomerCompany
        : ""
    setGlobalState((prevState) => ({
      // adiciona os valores do tipo de pessoa e também a url para envio de axios
      ...prevState,
      formData: {
        verifyType: verifyType,
        axiosUrl: axiosUrl,
        verifySelect: verifySelect,
        noEdit: true,
        inputDisable: true,
      },
      // passDelRepresentative: true,
      // sentCompany: false,
      // statusImage: '',
      // fieldErrors: '',
    }))
  }

  // editar
  const edit = (item) => {
    toggleData("id", item?.id) // adiciona id ao estado global
    onClickEdit(item?.id) // passa o id no click

    // verifyType usado em cliente
    const verifyType = "cnpj" in item ? asCompany : asPeople // muda o valor no estado global formData.verifyType de acordo com pessoa física ou jurídica
    const axiosUrl = verifyType === asPeople ? URL_PHYSICAL : URL_COMPANY // muda o valor no estado global formData.verifyType de acordo com pessoa física ou jurídica

    // verifySelect usado em letterOfAttorney
    const verifySelect = // muda o valor no estado global formData.verifySelect de acordo com pessoa física ou jurídica
      item.customer_person_physical !== null
        ? asCustomerPhysical
        : item.customer_person_company !== null
        ? asCustomerCompany
        : ""
    setGlobalState((prevState) => ({
      // adiciona os valores do tipo de pessoa e também a url para envio de axios
      ...prevState,
      formData: {
        verifyType: verifyType,
        axiosUrl: axiosUrl,
        verifySelect: verifySelect,
        noEdit: true,
        inputDisable: false,
      },
      // passDelRepresentative: true,
      // sentCompany: false,
      // statusImage: '',
      // fieldErrors: '',
    }))
  }

  // deletar
  const handleDelete = (id) => {
    toggleData("id", id) // adiciona id ao estado global
    onClickDelete(id) // passa o id no click
  }

  const sortedState = [...state].sort((a, b) => b.id - a.id) // organiza as tabelas em ordem de id

  const getNestedPropertyValue = (obj, path, styledFn) => {
    if (typeof path === "function") {
      return path(obj)
    }
    const pathArray = path.split(".")
    let value = obj

    for (const step of pathArray) {
      if (Array.isArray(value)) {
        return value.map((item) => getNestedPropertyValue(item, step, styledFn)).join(", ")
      }

      if (value && value.hasOwnProperty(step)) {
        value = value[step]
      } else {
        return null
      }
    }

    return styledFn ? styledFn(value) : value
  }

  return (
    <>
      <div style={{ "overflow-x": "auto" }}>
        <S.Table>
          <thead>
            <tr>
              {column.map((i, index) => (
                <S.Th key={index} className={i?.field === "acoes" ? "acoes" : ""}>
                  {i.name}
                </S.Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(sortedState) &&
              sortedState?.map((x, index) => (
                <tr key={index}>
                  {column.map((i, index) => (
                    <S.Td key={index} className={i?.field === "acoes" ? "acoes" : ""}>
                      {i.field === "acoes" ? (
                        <S.Span>
                          {componentName === "Propost" && (
                            <S.MiniIconButtonModal onClick={() => pdfMail(x)}>
                              <CheckCircleOutlineIcon />
                            </S.MiniIconButtonModal>
                          )}
                          {componentName === "LetterOfAttorney" && (
                            <S.MiniIconButtonModal onClick={() => pdfLetter(x)}>
                              <PictureAsPdfIcon />
                            </S.MiniIconButtonModal>
                          )}
                          {componentName === "Propost" && (
                            <S.MiniIconButtonModal onClick={() => IdGuard(x)}>
                              <EmailIcon />
                            </S.MiniIconButtonModal>
                          )}
                          {componentName === "Propost" && (
                            <S.MiniIconButtonModal onClick={() => pdfPropost(x)}>
                              <PictureAsPdfIcon />
                            </S.MiniIconButtonModal>
                          )}
                          {componentName === "Client" && (
                            <S.MiniIconButtonModal onClick={() => pdfClickCLient(x)}>
                              <PictureAsPdfIcon />
                            </S.MiniIconButtonModal>
                          )}

                          <S.MiniIconButtonModal onClick={() => detail(x)}>
                            <FindInPageIcon />
                          </S.MiniIconButtonModal>

                          <S.MiniIconButtonModal onClick={() => edit(x)}>
                            <EditIcon />
                          </S.MiniIconButtonModal>

                          <S.MiniIconButtonModalRed onClick={() => handleDelete(x.id)}>
                            <DeleteIcon />
                          </S.MiniIconButtonModalRed>
                        </S.Span>
                      ) : // se na tabela tiver imagem
                      i?.field === "image" ? (
                        getNestedPropertyValue(x, i?.field) ? (
                          <S.MiniIconButtonModal onClick={() => openImageModal(getNestedPropertyValue(x, i?.field))}>
                            <PhotoIcon />
                          </S.MiniIconButtonModal>
                        ) : (
                          "-" // quando não tem imagem
                        )
                      ) : typeof i?.styled === "function" ? (
                        i?.styled(getNestedPropertyValue(x, i?.field))
                      ) : (
                        getNestedPropertyValue(x, i?.field) || "-"
                      )}
                    </S.Td>
                  ))}
                </tr>
              ))}
          </tbody>
        </S.Table>
      </div>

      {/* Modal para exibir a imagem */}
      <Modal
        open={isModalOpen}
        onClose={() => {
          closeModal()
        }}
        onClick={() => closeModal()}
      >
        <S.Styled>
          <S.ContentStyle>
            {imageUrl && <img src={imageUrl} alt="Preview" style={{ height: "25rem" }} />}
          </S.ContentStyle>
        </S.Styled>
      </Modal>
    </>
  )
}

export default Table
