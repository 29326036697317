import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { removeZero, settingState } from '../../../constants/functions/functions';
import { URL_PROPOSAL } from '../../../constants/urls/urls';
import { GlobalContext } from '../../../global/GlobalContext';
import useForm from '../../../hooks/useForm';
import Formulary from '../../common/Formulary/Formulary/Formulary';
import InputsPropost from '../../common/Formulary/InputsPropost/InputsPropost';
import TitleModal from '../../common/Reuse/TitleModal/TitleModal';
import * as S from '../../common/StyledCommonModals/StyledCommonModals';

const DetailPropost = (props) => {
  const {
    states: {
      globalState: { id }
    },
    functions: { headerGlobalString }
  } = useContext(GlobalContext);
  const [clear, setClear] = useState(false);
  const { detail, setDetail } = props;

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage
  } = useForm();

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    detail,
    setDetail,
    clear,
    setClear,
    setFieldErrors
  };

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_PROPOSAL}${id}/`, {
        headers: headerGlobalString()
      });
      const data = response.data;
      const termConditionId = data.proposal_term_condition.id;
      const termConditionTypeIds = data.proposal_term_condition_type?.map(
        (type) => type.id
        );
      setDataForm((prevState) => ({
        ...prevState,
        ...data,
        project: data.project.id,
        term_condition: termConditionId,
        term_condition_type: termConditionTypeIds,
        value: removeZero(data.value),
        discount: removeZero(data.discount),
        down_payment: removeZero(data.down_payment)
      }));
    } catch (error) {
      console.log('Erro em getInfo');
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setDetail, !detail)}>
        <S.DivGap>
          <TitleModal text={'detalhes'} />
          <InputsPropost {...propsList} />
        </S.DivGap>
      </Formulary>
    </S.Main>
  );
};
export default DetailPropost;
